<template>
  <v-app class="split-bg">
    <div class="auth-bg">
<!--      <img src="@/assets/auth-bg.jpg" />-->
    </div>
    <div class="auth-bg-mask"></div>
    <v-content>
      <div class="position-abs align-central width-80p">
        <v-layout>
          <v-flex xs12 sm8 md4 lg4 style="width: 100%;margin: auto">
            <div class="system-sign-in">
              <v-card elevation="0" color="transparent" dark>
                <v-card-title class="d-block">
                  <h1 class="text-central">VERIFICATION</h1>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-text-field
                      prepend-inner-icon="request_page"
                      label="Loan #"
                      type="text"
                      v-model="dataSource.loanNo"
                      v-validate="'required|notEmpty'"
                      data-vv-name="loan #"
                      :error-messages="errors.collect('loanNo')"
                    ></v-text-field>
                    <v-text-field
                      prepend-inner-icon="email"
                      label="Email"
                      type="Email"
                      v-model="dataSource.email"
                      v-validate="'required|email'"
                      data-vv-name="email"
                      :error-messages="errors.collect('email')"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn icon class="size-100x100" @click="clickSignInEventHandler"
                         :loading="layout.loading">
                    <v-icon class="f-s-50">fingerprint</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>

              <v-snackbar
                color="error"
                v-model="layout.snackbar"
                multi-line
              >
                {{ layout.error }}
                <v-btn icon dark @click.native="layout.snackbar = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-snackbar>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-content>
  </v-app>
</template>

<script>

import { ExpresswayApi } from '../../api/expressway'
import { OriginationApi } from '../../api/application'

export default {
  data: () => ({
    dataSource: {
      loanNo: '',
      email: ''
    },
    layout: {
      loading: false,
      snackbar: false,
      error: ''
    }
  }),
  methods: {
    clickSignInEventHandler () {
      try {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.verify()
          }
        })
      } catch (e) {
        this.layout.error = e
        this.layout.loading = false
        this.layout.snackbar = true
      }
    },
    verify () {
      const _this = this
      ExpresswayApi.customerSearchByConditions(
        {
          loanNo: this.dataSource.loanNo,
          portfolioCode: 'ALL',
          force: true
        }, function (success) {
          if (success.length !== 1) {
            _this.$swal({
              type: 'warning',
              text: 'Incorrect input information, please check!'
            }).then(function () {
              _this.overlay = false
            })
            return false
          } else {
            const loan = success[0]
            if (_this.dataSource.email.toLowerCase() === loan.email.toLowerCase()) {
              OriginationApi.doEncrypt({ encryptStr: loan.id }, function (result) {
                _this.$router.push('/assist/instant-card/' + result)
              })
            } else {
              _this.$swal({
                type: 'warning',
                text: 'The E-mail does not match!'
              }).then(function () {
                _this.overlay = false
              })
            }
          }
        })
    }
  }
}
</script>

<style lang="css" scoped>
  .auth-bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .auth-bg img {
    width: 100%;
    height: 100%;
  }

  .auth-bg-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .text-central {
    text-align: center;
    align-self: center;
    justify-content: center;
  }

  .v-card__actions {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .v-card__actions .v-btn {
    margin: unset;
  }
  /*#app {*/
  /*  height: 100%;*/
  /*  width: 100%;*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  left: 0;*/
  /*  content: "";*/
  /*  z-index: 0;*/
  /*}*/

  /*#app:before, #app:after {*/
  /*  overflow: hidden;*/
  /*  position: absolute;*/
  /*  text-align: center;*/
  /*  font-size: 8rem;*/
  /*  background-image: -webkit-linear-gradient(45deg, #39c5bb, #bdbf78);*/
  /*  -webkit-background-clip: text;*/
  /*  -webkit-text-fill-color: transparent;*/
  /*  animation: shine 3s infinite;*/
  /*  line-height: 0.2;*/
  /*  font-family: "Roboto", sans-serif;*/
  /*  font-weight: 300;*/
  /*  height: 100px;*/
  /*  width: 100%;*/
  /*  content: "The integrity of the upright will guide themselves. The perverseness of a treacherous man will destroy himself.";*/
  /*}*/

  /*#app:after {*/
  /*  bottom: 0;*/
  /*  height: 100px;*/
  /*}*/

  /*@keyframes shine {*/
  /*  from {*/
  /*    -webkit-filter: hue-rotate(0deg);*/
  /*  }*/

  /*  to {*/
  /*    -webkit-filter: hue-rotate(-360deg);*/
  /*  }*/
  /*}*/
</style>
