import { ajax } from '@/js/extension'
const BASE_URL = '/gateway/platform-lmsapi/expressway'

const ExpresswayApi = {
  customerSearchByConditions (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/customer-search`,
      input,
      success,
      failure
    )
  }
}
export { ExpresswayApi }
